<template>
  <v-snackbar v-model="snackbar.state" :color="snackbar.color" right>
    {{ snackbar.text }}
  </v-snackbar>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
  name: 'Snackbar',

  computed: {
    ...mapState({
      snackbar: state => state.controls.snackbar
    })
  },

  watch: {
    'snackbar.state'(value) {
      if (value) {
        setTimeout(() => {
          this.callSnackbar({
            state: false,
            text: 'Snackbar message',
            color: 'success'
          })
        }, 2100)
      }
    }
  },

  methods: {
    ...mapMutations({
      callSnackbar: 'callSnackbar'
    })
  }
}
</script>
