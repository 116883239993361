<template>
  <v-app>
    <v-navigation-drawer v-model="controls.drawer" width="322px" floating app>
      <v-layout align-center justify-center column class="app-logo-container">
        <v-img
          :src="require('@/assets/icon.png')"
          height="100"
          width="100"
          class="rounded"
        ></v-img>
        <v-img
          :src="require('@/assets/logo-2.png')"
          max-width="240"
          class="mt-4"
        ></v-img>
      </v-layout>

      <v-list class="app-nav" flat>
        <template v-for="item in navItems">
          <v-list-item
            link
            active-class="white black--text"
            :key="item.label"
            :to="item.to"
            v-if="showFor(item.roles)"
          >
            <v-list-item-content>
              <v-list-item-title class="pa-2 app-nav--item">{{
                item.label
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
      <template v-slot:append>
        <v-list>
          <v-menu top offset-y>
            <template v-slot:activator="{ on }">
              <v-list-item two-line v-on="on">
                <v-list-item-avatar>
                  <user-avatar size="40" :avatar="user.avatar" />
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{ user.full_name }}</v-list-item-title>
                  <v-list-item-subtitle>
                    {{ user.email ? user.email : user.phone_number }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>
            <v-list>
              <v-list-item @click="logoutConfirmDialog = true">
                <v-list-item-title>Log out</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-list>
      </template>
    </v-navigation-drawer>
    <v-main class="main-content">
      <Snackbar />
      <!-- end snackbar -->
      <div class="px-xl-12 px-lg-10 px-md-6 px-sm-4 px-xs-2">
        <slot />
      </div>
    </v-main>
    <ConfirmModal
      v-model="logoutConfirmDialog"
      title="Logout"
      message="Are you sure you want to logout?"
    >
      <v-btn text @click="logoutConfirmDialog = false">Cancel</v-btn>
      <v-btn color="primary" text @click="logout">Logout</v-btn>
    </ConfirmModal>
  </v-app>
</template>

<script>
import ControlsMixin from '@/utils/mixins/Controls'
import ConfirmModal from '@/components/modals/ConfirmModal'
import { mapState, mapActions, mapGetters } from 'vuex'
import UserAvatar from '@/components/UserAvatar'
import Snackbar from '@/components/Snackbar'
import { includes } from 'lodash'

export default {
  name: 'MainLayout',

  components: {
    UserAvatar,
    ConfirmModal,
    Snackbar,
  },

  mixins: [ControlsMixin],

  data() {
    return {
      navItems: [
        {
          label: 'Users',
          to: { name: 'users' },
          roles: ['super_admin', 'org_manager'],
        },
        {
          label: 'Missions',
          to: { name: 'missions' },
          roles: ['super_admin'],
        },
        {
          label: 'Challenges',
          to: { name: 'challenges' },
          roles: ['super_admin'],
        },
        {
          label: 'Organisations',
          to: { name: 'organisations' },
          roles: ['super_admin'],
        },
        {
          label: 'Impact Projects',
          to: { name: 'charities' },
          roles: ['super_admin'],
        },
        {
          label: 'Flagged User Action',
          to: { name: 'flaggedUserActions' },
          roles: ['super_admin'],
        },
        {
          label: 'Flagged User',
          to: { name: 'flaggedUsers' },
          roles: ['super_admin'],
        },
        {
          label: 'Settings',
          to: { name: 'settings' },
          roles: ['super_admin'],
        },
        {
          label: 'Contents',
          to: { name: 'contents' },
          roles: ['super_admin'],
        },
        {
          label: 'User Management',
          to: { name: 'userManagement' },
          roles: ['super_admin'],
        },
        {
          label: 'Organisation',
          to: { name: 'organisation' },
          roles: ['org_manager'],
        },
      ],
      logoutConfirmDialog: false,
    }
  },

  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
    ...mapGetters({
      avatarThumb: 'auth/avatarThumb',
    }),
  },

  methods: {
    ...mapActions({
      logout: 'auth/logout',
    }),

    showFor(roles) {
      const role = this.user ? this.user.role : null
      return includes(roles, role)
    },
  },
}
</script>
